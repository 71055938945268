import './App.css';
import imgBg from './images/bg.jpg';

function App() {
  return (
    <div className="App">
      <img src={imgBg} id='bg' />        
      <div id='text'>
      <h1>Business Intelligence Data Subscription Engine</h1>
        <p>VÄLKOMMEN TILL <br /> www.bi-turbo.com</p>
        
        <a href='https://www.youtube.com/channel/UC0MwD3o75cX-6o8Jm7ofnSQ'>BISpine - Kolla in våra demos</a>
      </div>

    </div>
  );
}

export default App;
